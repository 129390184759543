<template>
  <!-- 页面name：督导模板 -->
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">督导任务发布</div>
      <div>
        <el-button
          type="primary"
          class="sheet-btn"
          v-if="isAdd"
          @click="addTask"
          icon="el-icon-plus"
          >新增任务</el-button
        >
      </div>
    </div>
    <!-- 表单 -->
    <el-form
      :model="form"
      onsubmit="return false"
      :inline="true"
      class="search-form"
    >
      <el-form-item>
        <el-select
          v-model="selectRange"
          class="select-input"
          clearable
          placeholder="请选择接收范围"
          multiple
          collapse-tags
          @change="handleRangeChange"
        >
          <el-option
            v-for="item in rangeOptions"
            :key="item.label"
            :label="item.name"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.supervisionTopic"
          class="select-input"
          placeholder="请输入督导主题"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.templateName"
          class="select-input"
          placeholder="请输入督导模板"
        />
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="form.supervisionMethod"
          class="select-input"
          clearable
          placeholder="请输入督导方式"
        >
          <el-option
            v-for="item in superviseTypeOptions"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="form.publishName"
          class="select-input"
          placeholder="请输入发布人"
        />
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun"
          >查询</el-button
        >
        <el-button icon="el-icon-refresh-right" @click="resetFun"
          >重置</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="isSelfPublish" @change="handleSelfFun"
          >自己发布</el-checkbox
        >
      </el-form-item>
    </el-form>
    <l-table
      :columns="columns"
      :dataSource="tableData"
      :pagination="pageFlag"
      :ipagination="page"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :loading="tableLoading"
      :showXh="true"
      :height="'calc(100% - 2.91667vw)'"
      :autoHeight="false"
    >
      <el-table-column
        prop="supervisionTopic"
        label="督导主题"
        align="center"
        min-width="220"
        slot="supervisionTopic"
      ></el-table-column>
      <el-table-column
        prop="templateName"
        label="督导模板"
        align="center"
        min-width="220"
        slot="templateName"
      ></el-table-column>
      <el-table-column
        prop="supervisionMethodName"
        label="督导方式"
        align="center"
        width="100"
        slot="supervisionMethodName"
      ></el-table-column>
      <el-table-column
        prop="totalScore"
        label="总分"
        align="center"
        slot="totalScore"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="receptionRangeName"
        label="接收范围"
        min-width="220"
        align="center"
        slot="receptionRangeName"
      >
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="light"
            popper-class="customPopper"
            :content="scope.row.receptionRangeName"
            placement="top"
            v-if="scope.row.receptionRangeName"
          >
            <div class="description-div">
              {{ scope.row.receptionRangeName }}
            </div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="jobName"
        label="岗位"
        align="center"
        slot="jobName"
      ></el-table-column>
      <el-table-column
        prop="publishName"
        label="发布人"
        align="center"
        width="90"
        slot="publishName"
      ></el-table-column>
      <el-table-column
        prop="timedSend"
        label="定时发布时间"
        width="150"
        align="center"
        slot="timedSend"
      >
        <template slot-scope="scope">
          <span>{{ !scope.row.timedSend ? "/" : scope.row.timedSend }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="sendTime"
        label="实际发布时间"
        width="150"
        align="center"
        slot="sendTime"
      ></el-table-column>
      <el-table-column
        prop="parkDeadline"
        label="截止日期"
        width="100"
        align="center"
        slot="parkDeadline"
      ></el-table-column>
      <el-table-column
        prop="statusName"
        label="状态"
        align="center"
        width="90"
        slot="statusName"
      ></el-table-column>
      <el-table-column
        label="操作"
        fixed="right"
        slot="handle"
        align="center"
        width="250"
      >
        <template slot-scope="scope">
          <el-button
            class="table-handle"
            @click="handleTaskFun('publish', scope.row)"
            type="text"
            v-if="
              scope.row.status === '2' &&
              isPublish &&
              userInfo.userId === scope.row.createBy
            "
            >提前发布</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('view', scope.row)"
            type="text"
            v-if="
              (scope.row.status === '2' || scope.row.status === '3') && isView
            "
            >查看</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('copy', scope.row)"
            type="text"
            v-if="
              (scope.row.status === '2' || scope.row.status === '3') && isCopy
            "
            >复制</el-button
          >
              <!-- scope.row.status === '2' && -->
          <el-button
            class="table-handle"
            @click="handleTaskFun('edit', scope.row)"
            type="text"
            v-if="
              isUpdate &&
              userInfo.userId === scope.row.createBy
            "
            >编辑</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('rePublish', scope.row)"
            type="text"
            v-if="
              scope.row.status === '4' &&
              isRePublish &&
              userInfo.userId === scope.row.createBy
            "
            >重新发布</el-button
          >
          <el-button
            class="table-handle"
            @click="handleTaskFun('errorList', scope.row)"
            type="text"
            v-if="
              scope.row.status === '4' &&
              isErrorList &&
              userInfo.userId === scope.row.createBy
            "
            >错误列表</el-button
          >
          <el-popconfirm
            confirm-button-text="确定"
            cancel-button-text="取消"
            icon="el-icon-warning"
            icon-color="#E6BD89"
            title="你确定要删除此任务吗？"
            @confirm="handleDel(scope.row)"
            v-if="
              (scope.row.status === '2' || scope.row.status === '4') &&
              isDelete &&
              userInfo.userId === scope.row.createBy
            "
          >
            <el-button
              slot="reference"
              type="text"
              class="table-handle"
              v-if="
                (scope.row.status === '2' || scope.row.status === '4') &&
                isDelete &&
                userInfo.userId === scope.row.createBy
              "
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </l-table>
    <div class="loading-dialog" v-if="loadingDialogVisible">
      <div class="loading-dialog-body">
        <img
          class="close-icon"
          :src="closeIcon"
          alt=""
          @click="handleLoadingClose"
        />
        <div class="loading-dialog-icon">
          <img
            :class="{ loading: loadingDialogVisible }"
            :src="publishLoadingIcon"
            alt=""
          />
        </div>
        <div class="loading-dialog-text">正在发布，请稍后，等待时间较长，您可关闭弹窗在列表中查看发布结果，也可以继续等待</div>
        <el-button  type="primary" class="close-btn" @click="closePage">关闭</el-button>
      </div>
    </div>
    <div class="result-dialog" v-if="resultDialogVisible">
      <div class="result-dialog-body">
        <img
          class="close-icon"
          :src="closeIcon"
          alt=""
          @click="handleResultClose"
          v-if="resultDialogType == 'fail'"
        />
        <div class="result-dialog-icon">
          <img
            :src="
              resultDialogType == 'success'
                ? publishSuccessIcon
                : publishFailIcon
            "
            alt=""
          />
        </div>
        <div class="result-dialog-text">
          {{ resultDialogType == "success" ? "发布成功" : "发布失败！" }}
        </div>
        <div
          class="result-dialog-little-text"
          v-if="resultDialogType == 'fail'"
        >
          以下园校未发现任务接收或处理响应人：
        </div>
        <div class="republish-button" v-if="resultDialogType == 'fail'">
          <el-button class="button" @click="handleErrorFun('batchRefresh')"
            >批量刷新</el-button
          >
          <el-button
            class="button"
            @click="handleTaskFun('rePublish', taskInfo)"
            v-if="isRePublish"
            >重新发布</el-button
          >
        </div>
        <l-table
          v-if="resultDialogType == 'fail'"
          :columns="errorColumns"
          :dataSource="errorTableData"
          :pagination="errorPageFlag"
          :ipagination="errorPage"
          @current-change="handleErrorListCurrentChange"
          @size-change="handleErrorListSizeChange"
          :loading="errorTableLoading"
          :showXh="true"
          :height="'20.83333vw'"
          :autoHeight="false"
        >
          <el-table-column
            prop="parkName"
            label="园校"
            align="center"
            slot="parkName"
          ></el-table-column>
          <el-table-column
            prop="jobName"
            label="岗位"
            align="center"
            slot="jobName"
          ></el-table-column>
          <el-table-column
            prop="receiverName"
            label="接收人"
            align="center"
            width="90"
            slot="receiverName"
          >
            <template slot-scope="scope">
              <span
                :style="{
                  color: scope.row.receiver == '' ? '#C6000B' : '#010334',
                }"
                >{{
                  scope.row.receiver == "" ? "无" : scope.row.receiverName
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="branchAuditName"
            label="分公司审批人"
            align="center"
            width="120"
            slot="branchAuditName"
          >
            <template slot-scope="scope">
              <span
                :style="{
                  color: scope.row.branchAudit == '' ? '#C6000B' : '#010334',
                }"
                >{{
                  scope.row.branchAudit == "" ? "无" : scope.row.branchAuditName
                }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            slot="handle"
            align="center"
            width="100"
          >
            <template slot-scope="scope">
              <el-button
                class="table-handle"
                @click="handleErrorFun('refresh', scope.row, scope.$index)"
                type="text"
                >刷新</el-button
              >
            </template>
          </el-table-column>
        </l-table>
      </div>
    </div>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
import publishLoadingIcon from "@/assets/img/publish-loading-icon.png";
import publishSuccessIcon from "@/assets/img/setting-success-icon.png";
import publishFailIcon from "@/assets/img/publish-fail-icon.png";
import closeIcon from "@/assets/img/icon_close.png";
export default {
  name: "superviseTemplate",
  components: { LTable },
  data() {
    return {
      dialogVisible: false,
      tableLoading: false, // tableloading
      page: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      pageFlag: false,
      columns: [
        {
          label: "督导主题",
          slotName: "supervisionTopic",
        },
        {
          label: "督导模板",
          slotName: "templateName",
        },
        {
          label: "督导方式",
          slotName: "supervisionMethodName",
        },
        {
          label: "总分",
          slotName: "totalScore",
        },
        {
          label: "接收范围",
          slotName: "receptionRangeName",
        },
        {
          label: "岗位",
          slotName: "jobName",
        },
        {
          label: "发布人",
          slotName: "publishName",
        },
        {
          label: "定时发布时间",
          slotName: "timedSend",
        },
        {
          label: "实际发布时间",
          slotName: "sendTime",
        },
        {
          label: "截止日期",
          slotName: "parkDeadline",
        },
        {
          label: "状态",
          slotName: "statusName",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      title: "",
      form: {
        receptionRange: "",
        supervisionTopic: "",
        templateName: "",
        supervisionMethod: "",
        jobId: "",
        publishName: "",
        status: "",
        createBy: "",
      },
      selectRange: [], //接收范围
      isSelfPublish: false, //是否自己发布
      superviseTypeOptions: [
        {
          name: "远程督导",
          value: "1",
        },
        {
          name: "现场督导",
          value: "2",
        },
      ], //督导方式下拉
      statusOptions: [
        {
          name: "发布中",
          value: "1",
        },
        {
          name: "待发布",
          value: "2",
        },
        {
          name: "已发布",
          value: "3",
        },
        {
          name: "发布失败",
          value: "4",
        },
      ], //状态下拉
      rangeOptions: [], //接收范围下拉
      tableData: [], //表单数据

      resultStatus: "", //发布结果
      statusSetIntervalObject: null, //循环请求对象

      loadingDialogVisible: false, //发布加载loading
      resultDialogVisible: false, //结果弹窗标识
      resultDialogType: "", //结果弹窗类型

      taskInfo: {}, //操作任务信息
      errorTableLoading: false, // tableloading
      errorPage: {
        num: 1,
        size: 10,
        total: 0,
      }, // 分页信息
      errorPageFlag: false,
      errorColumns: [
        {
          label: "园校",
          slotName: "parkName",
        },
        {
          label: "岗位",
          slotName: "jobName",
        },
        {
          label: "接收人",
          slotName: "receiverName",
        },
        {
          label: "分公司审批人",
          slotName: "branchAuditName",
        },
        {
          label: "操作",
          slotName: "handle",
        },
      ],
      errorTableData: [], //表单数据
      userInfo: {}, //登录用户信息

      publishLoadingIcon, //发布loadingicon
      publishSuccessIcon, //发布成功icon
      publishFailIcon, //发布失败icon
      closeIcon, //关闭icon

      permissionButtonList: [], // 权限按钮list
      isAdd: false, //新增任务
      isPublish: false, //提前发布
      isRePublish: false, //重新发布
      isErrorList: false, //错误列表
      isView: false, //查看
      isCopy: false, //复制
      isUpdate: false, //编辑
      isDelete: false, //删除
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      postOptions: (state) => state.common.postList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isAdd = val.indexOf("f_superviseTask:add") != -1;
          this.isPublish = val.indexOf("f_superviseTask:tqfb") != -1;
          this.isRePublish = val.indexOf("f_superviseTask:redeploy") != -1;
          this.isErrorList = val.indexOf("f_superviseTask:errorList") != -1;
          this.isView = val.indexOf("f_superviseTask:view") != -1;
          this.isCopy = val.indexOf("f_superviseTask:copy") != -1;
          this.isUpdate = val.indexOf("f_superviseTask:edit") != -1;
          this.isDelete = val.indexOf("f_superviseTask:del") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
    resultStatus(val) {
      if (val === true) {
        clearInterval(this.statusSetIntervalObject);
        this.statusSetIntervalObject = null;
        this.loadingDialogVisible = false;
        this.$nextTick(() => {
          this.resultDialogVisible = true;
          this.resultDialogType = "success";
          setTimeout(() => {
            this.resultDialogVisible = false;
            this.resultDialogType = "";
            this.tableLoading = true;
            this.init();
          }, 3000);
        });
      } else if (val === false) {
        clearInterval(this.statusSetIntervalObject);
        this.statusSetIntervalObject = null;
        this.loadingDialogVisible = false;
        this.$nextTick(() => {
          this.resultDialogVisible = true;
          this.resultDialogType = "fail";
          this.errorTableLoading = true;
          this.getErrorList();
        });
      }
    },
  },
  created() {
    let userInfo = sessionStorage.getItem("userInfo")
      ? JSON.parse(sessionStorage.getItem("userInfo"))
      : {};
    this.userInfo = userInfo;

    // this.getRangeList(() => {
    //   this.tableLoading = true;
    //   setTimeout(() => {
    //     this.init();
    //   }, 300);
    // });
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },
  activated(){
    this.getRangeList(() => {
      this.tableLoading = true;
      setTimeout(() => {
        this.init();
      }, 300);
    });
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    /** 初始化 */
    init() {
      let param = {};
      for (let key in this.form) {
        if (this.form[key]) {
          param[key] = this.form[key];
        }
      }
      param.level = "";
      param.branchId = this.userInfo.schoolList[0].companyLabel;
      param.current = this.page.num;
      param.size = this.page.size;
      this.$api
        .getTaskList(param)
        .then((res) => {
          if (res.data.code == 0) {
            this.tableLoading = false;
            console.log(res.data.data);
            res.data.data.records.map((item) => {
              // let rangeName = []
              // let rangeNameList = item.receptionRange ? item.receptionRange.split(",") : []
              // if(rangeNameList.length > 0 && this.rangeOptions.length > 0){
              //   rangeNameList.map((info) => {
              //     let rangeInfo = this.rangeOptions.find((rangeInfo) => { return rangeInfo.label == info })
              //     if(rangeInfo != null){
              //       rangeName.push(rangeInfo.name)
              //     }
              //   })
              // }
              item.receptionRangeName = item.receptionRange;

              let supervisionMethodName = "";
              let supervisionMethodIndex = this.superviseTypeOptions.findIndex(
                (superviseTypeInfo) => {
                  return superviseTypeInfo.value == item.supervisionMethod;
                }
              );
              if (supervisionMethodIndex != -1) {
                supervisionMethodName =
                  this.superviseTypeOptions[supervisionMethodIndex].name;
              }
              item.supervisionMethodName = supervisionMethodName;

              let statusName = "";
              let statusIndex = this.statusOptions.findIndex((statusInfo) => {
                return statusInfo.value == item.status;
              });
              if (statusIndex != -1) {
                statusName = this.statusOptions[statusIndex].name;
              }
              item.statusName = statusName;

              // let jobName = ""
              // let jobIndex = this.postOptions.findIndex((statusInfo) => { return statusInfo.value == item.jobId })
              // if(jobIndex != -1){
              //   jobName = this.postOptions[jobIndex].name
              // }
              // item.jobName = jobName
            });
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            if (res.data.data.total >= 1) {
              this.pageFlag = true;
            } else {
              this.pageFlag = false;
            }
          } else {
            this.tableLoading = false;
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.tableLoading = false;
        });
    },
    //获取接收范围
    getRangeList(callback) {
      this.$api
        .getCompanyDept({
          parent: this.userInfo.schoolList[0].companyLabel,
          type: "NODE_SCHOOL",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.rangeOptions = res.data.data || [];
            callback && callback();
          } else {
            this.$message.error(res.data.msg);
            callback && callback();
          }
        })
        .catch(() => {
          callback && callback();
        });
    },
    //处理选择范围变化
    handleRangeChange(val) {
      console.log(val);
      this.form.receptionRange = val.join(",");
    },
    //处理自我发布
    handleSelfFun(val) {
      if (val) {
        this.form.publishName = this.userInfo.userName;
      } else {
        this.form.publishName = "";
      }
      this.searchFun();
    },
    // 查询
    searchFun() {
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
    // 重置按钮
    resetFun() {
      this.form.receptionRange = "";
      this.form.supervisionTopic = "";
      this.form.templateName = "";
      this.form.supervisionMethod = "";
      this.form.jobId = "";
      if (!this.isSelfPublish) {
        this.form.publishName = "";
      }
      this.selectRange = [];
      this.form.status = "";
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
    /** 新增模板 */
    addTask() {
      this.$router.push({
        path: "/superviseTaskDetail",
        query: { type: "add", jumpType: "company" },
      });
    },
    /** 行内-编辑 */
    handleTaskFun(type, row) {
      switch (type) {
        case "publish":
          this.resultStatus = "";
          this.taskInfo = JSON.parse(JSON.stringify(row));
          this.loadingDialogVisible = true;
          this.$api
            .advanceTaskPublish(row.id)
            .then((res) => {
              if (res.data.code == 0) {
                this.refreshPublishStatus();
              } else {
                this.loadingDialogVisible = false;
                this.$message.error(res.data.msg);
              }
            })
            .catch(() => {
              this.loadingDialogVisible = false;
            });
          break;
        case "copy":
          this.$router.push({
            path: "/superviseTaskDetail",
            query: { id: row.id, type: "add", jumpType: "company" },
          });
          break;
        case "view":
          this.$router.push({
            path: "/superviseTaskDetail",
            query: { id: row.id, type: "view", jumpType: "company",supervisionTopic:row.supervisionTopic, },
          });
          break;
        case "edit":
          this.$router.push({
            path: "/superviseTaskDetail",
            query: { id: row.id, type: "edit", jumpType: "company" },
          });
          break;
        case "rePublish":
          this.resultStatus = "";
          this.taskInfo = JSON.parse(JSON.stringify(row));
          this.loadingDialogVisible = true;
          this.$api
            .republishTask(row.id)
            .then((res) => {
              if (res.data.code == 0) {
                this.refreshPublishStatus();
              } else {
                this.loadingDialogVisible = false;
                this.$message.error(res.data.msg);
              }
            })
            .catch(() => {
              this.loadingDialogVisible = false;
            });
          break;
        case "errorList":
          this.resultStatus = "";
          this.taskInfo = JSON.parse(JSON.stringify(row));
          this.resultDialogVisible = true;
          this.resultDialogType = "fail";
          this.errorTableLoading = true;
          this.getErrorList();
          break;
        default:
          break;
      }
    },
    //刷新发布状态
    refreshPublishStatus() {
      this.statusSetIntervalObject = setInterval(async () => {
        await this.$api
          .getTaskPublishStatus(this.taskInfo.id)
          .then((res) => {
            if (res.data.code == 0) {
              if (res.data.data === "3") {
                this.resultStatus = true;
              } else if (res.data.data === "4") {
                this.resultStatus = false;
              }
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {});
      }, 300);
    },
    //处理loading关闭
    handleLoadingClose() {
      this.loadingDialogVisible = false;
      clearInterval(this.statusSetIntervalObject);
      this.statusSetIntervalObject = null;
      this.taskInfo = null;
      this.tableLoading = true;
      this.init();
    },
    //关闭dialog
    handleResultClose() {
      this.resultDialogVisible = false;
      this.$nextTick(() => {
        this.errorTableData = [];
        this.errorPage.size = 10;
        this.errorPage.num = 1;
        this.errorPage.total = 0;
        this.resultDialogType = "";
        this.taskInfo = null;
      });
    },
    //获取错误信息list
    getErrorList() {
      this.$api
        .getTaskFailList({ listId: this.taskInfo.id })
        .then((res) => {
          this.errorTableLoading = false;
          if (res.data.code == 0) {
            let list = res.data.data.records;
            this.errorTableData = list;
            this.errorPage.total = res.data.data.total;
            if (res.data.data.total > 1) {
              this.errorPageFlag = true;
            } else {
              this.errorPageFlag = false;
            }
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    //刷新操作
    handleErrorFun(type, row, index) {
      if (type == "refresh") {
        this.$api
          .refreshTaskFailList(row.id)
          .then((res) => {
            if (res.data.code == 0) {
              let info = res.data.data;
              this.$set(this.errorTableData, index, info);
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {});
      } else if (type == "batchRefresh") {
        this.$api
          .batchRefreshTaskFailList(this.taskInfo.id)
          .then((res) => {
            if (res.data.code == 0) {
              this.errorTableLoading = true;
              this.getErrorList();
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch(() => {});
      }
    },
    /** 分页 */
    handleErrorListCurrentChange(val) {
      console.log(val);
      this.errorPage.num = val;
      this.errorTableLoading = true;
      this.getErrorList();
    },
    handleErrorListSizeChange(val) {
      console.log(val);
      this.errorPage.size = val;
      this.errorPage.num = 1;
      this.errorTableLoading = true;
      this.getErrorList();
    },
    /** 行内-删除 */
    handleDel(row) {
      this.$api
        .removeTask(row.id)
        .then((res) => {
          if (res.data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
              duration: 2000,
              onClose: () => {
                this.tableLoading = true;
                this.init();
              },
            });
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {});
    },
    /** 分页 */
    handleCurrentChange(val) {
      console.log(val);
      this.page.num = val;
      this.tableLoading = true;
      this.init();
    },
    handleSizeChange(val) {
      console.log(val);
      this.page.size = val;
      this.page.num = 1;
      this.tableLoading = true;
      this.init();
    },
    // 保存中
    closePage(){
      this.loadingDialogVisible = false
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  background-color: #ffffff;
  height: calc(100% - 56px);
  padding: 26px 30px 30px 30px;
  .form-header-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    .header-title {
      color: #3d3e4f;
      font-size: 22px;
      font-weight: bold;
    }
    .sheet-btn {
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
      width: auto;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
  /deep/.el-table {
    // margin-top: 14px;
  }
  /deep/.el-table th > .cell {
    padding-left: 14px;
    border-left: 2px solid #e4ebf5;
  }
  /deep/.el-table th:first-child > .cell {
    border-left: transparent;
  }
  /deep/.el-switch__label.is-active {
    color: #010334;
  }
  /deep/.el-switch__label {
    color: #010334;
  }
  .table-handle {
    margin-right: 10px;
    margin-left: 0;
  }
  .tableList {
    height: calc(100% - 115px);
  }
  .description-div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .select-input {
    width: 200px;
  }
}
.loading-dialog-text{
  padding:0 40px;
  line-height: 50px;
}
.close-btn{
  margin: 30px 0;
}
.loading-dialog-body{
  padding-top:30px;
  min-height: 300px;
}
</style>